<template>
  <div class="req-temp-container new-user-introducer mt-6">
    <!-- user role card starts here -->
    <vs-row class="mt-6">
      <vs-col vs-w="12">
        <vs-card>
          <div slot="header">
            <h3 class="font-normal lg:text-2xl md:text-xl text-lg">
              User roles <span class="mid-blue">*</span>
            </h3>
          </div>
          <!-- radio choose role -->
          <vs-row class="px-4 md:px-8 pt-4 md:pt-6 pb-4">
            <vs-col v-if="!isEdit() || !isApiUser(formData.userType)" class="radioButtonCard w-auto mr-4 md:mr-6 mb-4 md:mb-6">
              <div
                class="radio-card h-full"
                @click="activate('admin')"
                :class="{ active: userTypeForCss == 'admin' }"
              >
                <vs-row>
                  <vs-col
                    vs-w="10"
                    vs-sm="10"
                    vs-lg="10"
                    vs-xs="10"
                    class="radio-label text-lg md:text-xl font-medium leading-tight"
                  >
                    Admin
                  </vs-col>
                  <vs-col
                    vs-w="2"
                    vs-sm="2"
                    vs-lg="2"
                    vs-xs="2"
                    class="flex items-center justify-center"
                  >
                    <vs-radio
                      v-model="formData.userType"
                      vs-value="admin"
                      vs-name="userType"
                    >
                    </vs-radio>
                  </vs-col>
                </vs-row>

                <vs-row>
                  <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                    <div>
                      <span class="radio-info">
                        Full access including editing of global account
                        settings</span
                      >
                    </div>
                  </vs-col>
                </vs-row>
              </div>
            </vs-col>
            <!-- User  -->
            <vs-col v-if="!isEdit() || !isApiUser(formData.userType)" class="radioButtonCard w-auto mr-4 md:mr-6 mb-4 md:mb-6">
              <div
                class="radio-card h-full"
                @click="activate('user')"
                :class="{ active: userTypeForCss == 'user' }"
              >
                <vs-row>
                  <vs-col
                    vs-w="10"
                    vs-sm="10"
                    vs-lg="10"
                    vs-xs="10"
                    class="radio-label text-lg md:text-xl font-medium leading-tight"
                  >
                    User
                  </vs-col>
                  <vs-col
                    vs-w="2"
                    vs-sm="2"
                    vs-lg="2"
                    vs-xs="2"
                    class="flex items-center justify-center"
                  >
                    <vs-radio
                      v-model="formData.userType"
                      vs-value="user"
                      vs-name="userType"
                    >
                    </vs-radio>
                  </vs-col>
                </vs-row>

                <vs-row>
                  <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                    <div>
                      <span class="radio-info">
                        Granted permissions based on user groups they are added
                        to</span
                      >
                    </div>
                  </vs-col>
                </vs-row>
              </div>
            </vs-col>

            <!-- User  -->
            <vs-col v-if="!isEdit() || isApiUser(formData.userType)" class="radioButtonCard w-auto mr-4 md:mr-6 mb-4 md:mb-6">
              <div
                class="radio-card h-full"
                @click="activate('apiUser')"
                :class="{ active: userTypeForCss == 'apiUser' }"
              >
                <vs-row>
                  <vs-col
                    vs-w="10"
                    vs-sm="10"
                    vs-lg="10"
                    vs-xs="10"
                    class="radio-label text-lg md:text-xl font-medium leading-tight"
                  >
                    API User
                  </vs-col>
                  <vs-col
                    vs-w="2"
                    vs-sm="2"
                    vs-lg="2"
                    vs-xs="2"
                    class="flex items-center justify-center"
                  >
                    <vs-radio
                      v-model="formData.userType"
                      vs-value="apiUser"
                      vs-name="userType"
                    >
                    </vs-radio>
                  </vs-col>
                </vs-row>

                <vs-row>
                  <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                    <div>
                      <span class="radio-info">
                        Access via API only - granted permissions based on user
                        groups the account is added to</span
                      >
                    </div>
                  </vs-col>
                </vs-row>
              </div>
            </vs-col>
          </vs-row>
        </vs-card>
      </vs-col>
    </vs-row>

    <!-- user role card ends here -->

    <!-- user details card starts here -->
    <vs-row>
      <vs-col vs-w="12">
        <vs-card v-if="formData.userType != 'apiUser'">
          <div slot="header">
            <h3 class="font-normal lg:text-2xl md:text-xl text-lg">
              User details <span class="mid-blue">*</span>
            </h3>
          </div>
          <vs-row class="px-0 py-4 md:py-6">
            <vs-col vs-w="6" vs-lg="4" vs-sm="12">
              <div class="input-container mb-6 pl-4 md:pl-8 pr-10 lg:pr-12">                
                <label class="w-full text-base font-light mb-1 block"
                  >Full name<span class="mid-blue">*</span></label
                >
                <vs-input
                  v-validate="'required'"
                  v-model="formData.name"
                  name="name"
                  id="name"
                  :v-validate="'nameRules'"
                  class="w-full"
                />
                <span class="text-danger text-sm" v-show="errors.has('name')">{{
                  errors.first("name")
                }}</span>                
              </div>
            </vs-col>
            <vs-col vs-w="6" vs-lg="4" vs-sm="12" class="mb-6 pl-4 md:pl-8 pr-10 lg:pr-12">
              <div class="input-container">                
                <label class="w-full text-base font-light mb-1 block"
                  >Email <span class="mid-blue">*</span></label
                >
                <vs-input
                  class="w-full"
                  v-validate="'required|email'"
                  size="large"
                  v-model="formData.email"
                  name="email"
                  id="email"  
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('email')"
                  >{{ errors.first("email") }}</span
                >                
              </div>
            </vs-col>
          </vs-row>
        </vs-card>
      </vs-col>
    </vs-row>

    <!-- api user details card starts here -->
    <vs-row>
      <vs-col vs-w="12">
        <vs-card v-if="formData.userType == 'apiUser'">
          <div slot="header">
            <h3 class="font-medium lg:text-2xl md:text-xl text-lg">Details <span class="mid-blue">*</span></h3>
          </div>
          <vs-row class="px-0 py-4 md:py-6">
            <vs-col vs-w="6" vs-lg="4" vs-sm="12">
              <div class="mb-6 pl-4 md:pl-8 pr-10 lg:pr-12">
                <label class="w-full text-base mb-1 font-light block">System name<span class="mid-blue">*</span></label>
                
                <vs-input
                  class="w-full"
                  v-validate="'required'"
                  size="large"
                  v-model="formData.systemName"
                  name="name"
                />
                <span class="text-danger text-sm" v-show="errors.has('name')">{{
                  errors.first("name")
                }}</span>
              </div>
            </vs-col>

            <vs-col vs-w="6" vs-lg="4" vs-sm="12" class="mb-6 pl-4 md:pl-8 pr-10 lg:pr-12">
              <div class="input-container">
                <label class="w-full text-base mb-1 font-light block">Description<span class="mid-blue">*</span></label>
                <vs-textarea
                  class="mb-1 w-15"
                  counter="300"
                  maxlength="300"
                  :counter-danger.sync="counterDanger"
                  v-model="formData.description"
                  rows="2"
                />
                
              </div>
            </vs-col>
          </vs-row>
        </vs-card>


         <vs-card class="mt-6" v-if="formData.userType === 'apiUser'">
      <div slot="header">
        <h3 class="font-medium lg:text-2xl md:text-xl text-lg">Api access token</h3>
      </div>
      <vs-row class="px-4 md:px-8 py-4 md:py-8">
      
        <div class="flex items-center">
          <vs-input
            class="w-full token"
            v-model="apiUserToken"
            disabled
            style="margin-right: 40px"
          />
          <span @click="copyTokenToClipboard">
            <vs-tooltip text="Copy to clipboard" class="pointer">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 9H11C9.89543 9 9 9.89543 9 11V20C9 21.1046 9.89543 22 11 22H20C21.1046 22 22 21.1046 22 20V11C22 9.89543 21.1046 9 20 9Z"
                  stroke="#757575"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5"
                  stroke="#757575"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </vs-tooltip>
          </span>
        </div>
      </vs-row>
    </vs-card>


      </vs-col>
    </vs-row>

    <!-- user groups ends here -->
    <vs-row class="mt-8">
      <vs-col vs-w="12" class="flex justify-end justify-end">
        <vs-button
          size="large"
          class="custom-button only-border-btn mr-8"
          @click="deleteButton"
          v-if="$route.name == 'user-edit'"
          v-round
          >Delete user</vs-button
        >
        <vs-button size="large" class="primary mr-8" @click="saveUser" v-round
          >Save</vs-button
        >
        <a
          @click="onCancel"
          class="edit-profile flex items-center text-sm text-button"
          v-round
          >Cancel</a
        >
      </vs-col>
    </vs-row>
    <leave-popup
      :popup-active="popupActive"
      @handleClick="popActions"
    ></leave-popup>

    <pop-up :errorsLists="errors" form-name="UserForm"></pop-up>

    <vs-popup
      title="Delete this user?"
      class="delete-pop-up text-lg md:text-2xl"
      :background-color-popup="colorx"
      :active.sync="deletePopupActive"
    >
      <div style="max-width:608px">
      <p class="mb-6 text-gray-font">
        Are you sure you want to permanently delete this user? You can't undo
        this action.
      </p>
      
      <div class="error-message-container px-6 py-5">
        <vs-icon icon="warning_amber" size="1.5rem" class="mr-4"></vs-icon>
        <div>
          <h4 class="text-xl mb-3 font-semibold text-danger">WARNING</h4>          
          <p class="text-dark-blue mb-4">
            By deleting this user you are permanently removing their access to
            this platform.
          </p>
        </div>
      </div>
      </div>
      <div class="button-combo flex items-center flex-row-reverse mt-8 md:mt-10 lg:mt-12">
        <a @click="closeButton" class="ml-8 text-button text-sm"
          >Cancel</a
        >
        <vs-button @click="deleteUser" size="large" v-round class="custom-button delete-user-btn"
          >Delete user</vs-button
        >
      </div>
    </vs-popup>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
import { TheMask } from "vue-the-mask";
import LeavePopup from "@/views/components/LeavePopup";
import PopUp from "@/views/components/ValidationPopup.vue";
import { XIcon } from "vue-feather-icons";
import { leavePopUpMixin } from "../../../mixins/leave-pop-up";

const dictionary = {
  en: {
    attributes: {
      firstName: "first name",
      lastName: "last name",
    },
    messages: {
      regex: () => "Phone number should start with 04 and should be 10 digits",
    },
  },
};
Validator.localize(dictionary);
export default {
  mixins: [leavePopUpMixin],
  components: { TheMask, PopUp, LeavePopup, XIcon,  },
  data() {
    return {
      colorx: "#FFFFFF",
      pattern: require("@/assets/images/pattern.png"),
      userTypeForCss: "admin",
      serverUrl: process.env.VUE_APP_API_URL,
      popupActive: false, // determines if the leave popup is to be shown
      deletePopupActive: false,
      showValidationPopUp: false, // determines if the leave popup is to be shown
      isSaved: false,
      nextObj: "", // determines the next route,
      counterDanger: "",
      formData: {
        userType: "admin",
        email: "",
        name: "",
        systemName: "",
        description: "",
        introducer: "",
      },
      apiUserToken:""
    };
  },
  methods: {
    ...mapActions("user", [
      "addNewUser",
      "fetchUserById",
      "updateUserDetails",
      "removeUser",
    ]),
    ...mapActions("admin", ["checkLogin"]),
    deleteButton() {
      this.deletePopupActive = true;
    },
    closeButton() {
      this.deletePopupActive = false;
    },
    onCancel() {
      this.isSaved = true;
      this.$router.push({ name: "user-list" });
    },

    activate(el) {
      this.formData.userType = el;
      this.userTypeForCss = el;
    },
    async getUserById(id) {
      await this.fetchUserById(id)
        .then((result) => {
          this.formData.name = result.data.data.name;
          this.formData.email = result.data.data.email;
          this.formData.userType = result.data.data.userType;
          this.userTypeForCss = result.data.data.userType;
          this.formData.systemName = result.data.data.systemName;
          this.formData.description = result.data.data.description;
          this.apiUserToken = result.data.data.thirdPartyAcessToken
        })
        .catch((err) => {});
    },

    async deleteUser() {
      await this.removeUser(this.$route.params.id)
        .then((result) => {
          this.showMessage(
            "Successful",
            "User has been updated successfully.",
            "success",
            "icon-check-circle"
          );
          this.isSaved = true;
          this.$router.push({ name: "user-list" });
        })
        .catch((err) => {
          this.showMessage(
            "Error",
            err.response.data.message,
            "danger",
            "icon-times"
          );
          
        });
    },
    async saveUser() {
      this.$vs.loading();
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          this.formData.accountStatus = "Active";
          this.formData.introducer = this.introducerId;
          if(this.formData.userType === "apiUser"){
            delete this.formData.email;
            delete this.formData.name;
          }
          let obj = {
            id: this.$route.params.id,
            data: this.formData,
          };
          if (this.$route.params.id) this.updateUser(obj);
          else this.storeUser(obj.data);
          this.isSaved = true;
          this.$vs.loading.close();
        }
        this.$vs.loading.close();
      });
      this.$vs.loading.close();
    },

    async updateUser(obj) {
      this.$vs.loading();
      await this.updateUserDetails(obj)
        .then((result) => {
          this.$vs.loading.close();
          this.showMessage(
            "Successful",
            "User has been updated successfully.",
            "success",
            "icon-check-circle"
          );
          this.isSaved = true;
          this.$router.push({ name: "user-list" });
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.showMessage(
            "Error",
            err.response.data.message,
            "danger",
            "icon-times"
          );
           if(err.response.status == 422 && err.response.data.title === 'Email'){
            this.errors.add({
              field: "email",
              msg: err.response.data.message,
            })
          }
          
        });
    },

    async storeUser(obj) {
      this.$vs.loading();

      await this.addNewUser(obj)
        .then((result) => {
          this.$vs.loading.close();
          this.showMessage(
            "Successful",
            "User has been created successfully.",
            "success",
            "icon-check-circle"
          );
          this.isSaved = true;
          this.$router.push({ name: "user-list" });
        })
        .catch((err) => {
          
          this.$vs.loading.close();
          this.showMessage(
            "Error",
            err.response.data.message,
            "danger",
            "icon-times"
          );
          if(err.response.status == 422 && err.response.data.title === 'Email'){
            this.errors.add({
              field: "email",
              msg: err.response.data.message,
            })
          }
          
          
        });
    },

     copyTokenToClipboard() {
      navigator.clipboard.writeText(this.apiUserToken).then(() => {
        this.$vs.notify({
          title: "Copy to Clipboard",
          text: "Copied text to clipboard",
          color: "success",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-check-circle",
        });
      });
    },

    showMessage(
      title = "Successful",
      message = "Task completed",
      color = "success",
      icon = "icon-check-circle"
    ) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },
    async checkAdminLogin() {
      await this.checkLogin();
    },

    isApiUser(userType) {
      return userType === 'apiUser';
    },

    isEdit() {
      return this.$route.params.hasOwnProperty('id') && this.$route.params.id.length > 0;
    }
  },
  beforeRouteLeave(to, from, next) {
    //check here
    if (this.isFormDirty && !this.isSaved) {
      this.nextObj = next;
      this.popupActive = true;
      localStorage.setItem("redirectTo", to.name || undefined);
    } else {
      next();
    }
  },
  mounted() {
    if (this.$route.params.id) this.getUserById(this.$route.params.id);
    this.checkAdminLogin();
  },
  created() {
    this.$validator.extend("nameRules", {
      getMessage: () => "The name should consist at least two words.",
      validate: (value) => value.match(/^[a-z]+(?:\s[a-z]+)+$/) !== null,
    });
  },
   watch: {
    isFormDirty(latest, previous) {
      if (latest) localStorage.setItem("formFieldUpdated", "yes");
    },

    isSaved(latest, previous) {
      if (!latest) localStorage.setItem("formFieldUpdated", "yes");
    }
  },

  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },
    leavePage() {
      return this.popupAnswer;
    },

    introducerId() {
      return this.user.userType === "superAdmin"
        ? this.user._id
        : this.user.introducer;
    },
  },
};
</script>
